import { ApolloProvider } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { useAtomValue } from "jotai/react";
import type { ReactNode } from "react";
import { apolloClientAtom } from "./apolloClientAtom.tsx";

if (import.meta.env.DEV) {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
}

export function DefaultApolloProvider({ children }: { children: ReactNode }) {
    const client = useAtomValue(apolloClientAtom);
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default DefaultApolloProvider;
